const isGtagAvailable = () => typeof window !== 'undefined' && typeof window.gtag === 'function';
const isDataLayerAvailable = () => typeof window !== 'undefined' && Array.isArray(window.dataLayer);

const trackEvent = ({ action, category, label, value, conversionLabel, page }) => {
  const pageLocation = page || (typeof window !== 'undefined' ? window.location.href : '');


  if (isGtagAvailable()) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
      send_to: conversionLabel,
      page_location: pageLocation,
    });
  }

  if (isDataLayerAvailable()) {
    window.dataLayer.push({
      event: action,
      event_category: category,
      event_label: label,
      value,
      conversion_label: conversionLabel,
      page_location: pageLocation,
    });
  }


  if (!isGtagAvailable() && !isDataLayerAvailable()) {
    console.warn('Ni Google Analytics ni Google Tag Manager están disponibles.');
  }
};


export const trackLongPageVisit = () => {
  if (isGtagAvailable() || isDataLayerAvailable()) {
    setTimeout(() => {
      trackEvent({
        action: 'long_page_visit',
        category: 'Page Visit',
        label: 'Vista de página + de 1 min',
        value: 1,
        conversionLabel: 'VLG6CKrBnfUZEKna9O8D',
      });
    }, 60000); // Espera de 60 segundos
  } else {
    console.warn('Ni Google Analytics ni Google Tag Manager están disponibles.');
  }
};


export const trackBrochureDownload = (projectName) => {
  trackEvent({
    action: 'brochure_download',
    category: 'Brochure',
    label: `Brochure descargado de ${projectName}`,
    value: 1,
    conversionLabel: 'gQzSCK3BnfUZEKna9O8D',
  });
};


export const trackSearch = (searchQuery) => {
  trackEvent({
    action: 'search',
    category: 'Search',
    label: `Búsquedas realizadas: ${JSON.stringify(searchQuery)}`,
    value: 1,
    conversionLabel: '8iqwCLDBnfUZEKna9O8D',
  });
};


export const trackFormSubmission = (formName) => {
  trackEvent({
    action: 'form_submission',
    category: 'Contact Form',
    label: `Consulta desde emprendimiento: ${formName}`,
    value: 1,
    conversionLabel: 'P7CtCPe5g4gBEKna9O8D',
  });
};




/*

const isGtagAvailable = () => typeof window !== 'undefined' && window.gtag;


export const trackEvent = ({ action, category, label, value, conversionLabel, page }) => {
  if (isGtagAvailable()) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
      send_to: conversionLabel,
      page_location: page || window.location.href, 
    });
  } else {
    console.warn('Google Analytics no está disponible.');
  }
};


export const trackLongPageVisit = () => {
  if (isGtagAvailable()) {
  
  setTimeout(() => {
    trackEvent({
      action: 'long_page_visit',
      category: 'Page Visit',
      label: 'Vista de página + de 1 min',
      value: 1,
      conversionLabel: 'VLG6CKrBnfUZEKna9O8D',
      page: window.location.href, 
    });
  }, 60000); }
  else {
    console.warn('Google Analytics no está disponible.');
  }

};


export const trackBrochureDownload = (projectName) => {

  trackEvent({
    action: 'brochure_download',
    category: 'Brochure',
    label: `Brochure descargado de ${projectName}`,
    value: 1,
    conversionLabel: 'gQzSCK3BnfUZEKna9O8D',
    page: window.location.href, 
  });

};


export const trackSearch = (searchQuery) => {

  trackEvent({
    action: 'search',
    category: 'Search',
    label: `busquedas realizada: ${JSON.stringify(searchQuery)}`,
    value: 1,
    conversionLabel: '8iqwCLDBnfUZEKna9O8D',
    page: window.location.href, 
  });

};

// Función para trackear envío de formularios
export const trackFormSubmission = (formName) => {
  trackEvent({
    action: 'form_submission',
    category: 'Contact Form',
    label: `consulta desde emprendimiento: ${formName}`,
    value: 1,
    conversionLabel: 'P7CtCPe5g4gBEKna9O8D',
    page: window.location.href, 
  });
};
*/